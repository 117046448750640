html[data-theme='light'] {
    --main-bg-color:  rgba(229, 229, 229, 0.5);
    --main-menu-color:  white;
    --main-table-color: white;
    --main-text-color: black;
    --main-text-addition-color: rgba(0, 0, 0, 0.6);
    --main-selection-color: rgba(0, 56, 255, 0.13);
    --main-selection-hover-color: rgba(0, 56, 255, 0.05);
    --main-underline-color: rgba(114, 114, 114, 0.41);
}

html[data-theme='dark'] {
    --main-bg-color:  rgba(0,0,0,0.9);
    --main-menu-color:  black;
    --main-table-color: black;
    --main-text-color: Gainsboro;
    --main-text-addition-color: rgba(229, 229, 229, 0.5);
    --main-selection-color: rgba(163, 163, 229, 0.8);
    --main-selection-hover-color: rgba(163, 163, 229, 0.5);
    --main-underline-color: rgba(229, 229, 229, 0.5);
}