.date-elem {
    height: 4.5vh;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 2vw;
}

.date-section {
    bottom: 11vh;
    display: flex;
    flex-direction: column;
    position: absolute;
}


.nav-list {
    overflow-x: hidden;
    white-space: nowrap;
    background-color: white;
    background-color: var(--main-menu-color);
    bottom: 0;
    display: block;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 100%;
}

.logo-img {
    display: block;
    max-width: 80%;
    max-height: 80%;
    margin: 3vh auto 3vh auto;
}

.logo-container {
    height: 30%;
    display: block;
}

.week-elem {
    font-weight: 200;
    height: 4.5vh;
    margin-bottom: 2.8vh;
    margin-top: 0;
    padding-left: 3.9vw;
}

.list-elem {
    color: black;
    color: var(--main-text-color);
    white-space: nowrap;
    display: block;
    position: relative;
    margin: 0 0.2vw;
    width: auto;
}

.list-elem:hover {
    background-color: rgba(0, 56, 255, 0.05);
    background-color: var(--main-selection-hover-color);
    border-radius: 10px;
}

.select {
    background-color: rgba(0, 56, 255, 0.13);
    background-color: var(--main-selection-color);
    border-radius: 10px;
}

.select:hover {
    background-color: rgba(0, 56, 255, 0.13);
    background-color: var(--main-selection-color);
}

.toggle-container{

    position:absolute;
    margin-left: 10px;
    bottom: 20px;
}

.toggle--on{
}

.toggle--off{
}

.switch-input {
    display: none;
}
.switch-label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
    padding: 16px 0 16px 36px;
}
.switch-label:before, .switch-label:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.switch-label:before {
    left: 1px;
    width: 34px;
    height: 14px;
    background-color: #9E9E9E;
    border-radius: 8px;
}
.switch-label:after {
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #FAFAFA;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
}


.switch-input:checked + .switch-label:after {
    background-color: gray;
    -ms-transform: translate(80%, -50%);
    -webkit-transform: translate(80%, -50%);
    transform: translate(80%, -50%);
}
