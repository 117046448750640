.first-line{
    border-left: 2px solid rgb(203, 203, 203);
    border-bottom: 2px solid rgb(203, 203, 203);
}

.last-line{
    border-left: 2px solid rgb(203, 203, 203);
}

.middle-line{
    position: relative;
    border-left: 2px solid rgb(203, 203, 203);
    border-bottom: 2px solid rgb(203, 203, 203);
}

.middle-time{
    border-top: 2px solid rgb(203, 203, 203);
    border-bottom: 2px solid rgb(203, 203, 203);
}

.time{
    width: 4vw;
    text-align: center;
}

h2{
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}
