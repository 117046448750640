header {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 95%;
}

.group-section {
    display: block;
    height: 100%;
    margin: 1vh auto;
    position: relative;
    width: 95%;
}

#header-icon {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
    white-space: pre-line;
}

.group {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    font-size: 1.55vw;
    font-style: normal;
    font-weight: normal;
}

.faculty {
    display: inline-block;
    margin: 0;
    width: auto;
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}

.underline-group {
    background-color: rgba(114, 114, 114, 0.41);
    background-color: var(--main-underline-color);
    height: 0.5px;
    margin: 0;
    width: auto;
}


.is_your_schedule {
    cursor: pointer;
    padding-left: 0.4vw;
    width: 1vw;
}

.h2_container {
    bottom: 0;
    display: table;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
}

.search-form {
    bottom: 0;
    display: table;
    margin: 0;
    padding: 0;
    position: relative;
    right: 0;
}

.h2-search-wrapper {
    position: relative;
    justify-content: space-between;
    flex-flow: row wrap;
    display: flex;
    padding: 0;
    margin: 0;
    bottom: 0;
}