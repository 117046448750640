input {
    border: none;
    display: inline;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    width: auto;
}


#search-icon {
    display: inline-block;
    position: relative;
    top: 0.28vh;
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}

.search-wrapper {
    height: 20px;
    bottom: 0;
    display: table;
    margin: 0;
    padding: 0;
    position: relative;
    white-space: nowrap;
}

.field-search {
    display: inline-block;
    background: transparent;
    border: none transparent;
    color: #8d8d8d;
    color: var(--main-text-color);
    margin: 0;
    outline: 0;
    outline-offset: 0;
    padding: 0;
}

.underline-search {
    background-color: rgba(114, 114, 114, 0.41);
    background-color: var(--main-underline-color);
    height: 0.5px;
    margin: 0;
    width: auto;
}

.dropdown {
    display: inline-block;
    position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    background-color: white;
    background-color: var(--main-menu-color);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    display: block;
    min-width: 13vw;
    position: absolute;
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    cursor: pointer;
    display: block;
    padding: 12px 16px;
    text-decoration: none;
}
.dropdown-content a:first-child {
    background-color: white;
    background-color: var(--main-menu-color);
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: rgba(0, 56, 255, 0.05);
    background-color: var(--main-selection-hover-color);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}