.rows-container{
    border-radius: 20px;
    margin: auto;
    position: relative;
    justify-content: space-between;
    height: 90vh;
    overflow-y: auto;
    width: 100%;
}

#scrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background: white;
    background: var(--main-table-color);
}

#scrollbar::-webkit-scrollbar
{
    width: 10px;
    background: white;
    background: var(--main-table-color);
}

#scrollbar::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

#scrollbar{
    scroll-behavior: smooth;
    overflow-y: scroll;
    height: 90%;
}

#hide-list{
    display: none;
}

.teachers-card-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 5px;
    width: 95%;
}

.faculty-li{
    cursor: pointer;
    font-weight: 500;
    border: #0038FF21 double;
    border-radius: 15px;
    padding: 5px;
    margin-top: 5px;

    background: white;
    background: var(--main-table-color);
    width: 95%;
}

.faculty-li:hover{
    background: #0038FF21;
}

.department-li-title{
    cursor: pointer;
    font-weight: normal;
    border-left: 5px #0038FF21 solid;
    margin: 5px;
    padding-left: 5px;
}

.teachers-card-container{
    margin-top: 10px;
}

.faculty-list{
    list-style-type: none;
}

.department-list{
    list-style-type: none;
}

.department-li-title:hover{
    background: rgba(0, 56, 255, 0.05);
}
