.change-week{
    margin: 0 auto;
    width: 95%;
    height: 100%;
    position: relative;
}


.change-week-section {
    margin: auto 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#next-icon{
    cursor: pointer;
    width: 50%;
    text-align: right;
}

#back-icon {
    cursor: pointer;
    text-align: left;
    width: 50%;
}

.week, .date-week  {
    text-align: center;
    margin: auto 0;
    white-space: nowrap;
}

.underline-week {
    background-color: rgba(114, 114, 114, 0.41);
    height: 1px;
    width: 100%;
    margin: 0;
}