.background-table {
    background: white;
    background: var(--main-table-color);
    border-radius: 20px;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 7% 1fr 7%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

.grid-content {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 9% 1fr;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

.search-main-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.schedule-week {
    margin: auto;
    width: 95%;
}

table {
    border-collapse: collapse;
    height: 95%;
    position: relative;
    table-layout: fixed;
}

.table-wrapper {
    height: 98%;
    margin: auto;
    position: relative;
    width: 98%;
}

.underline-week-days {
    background-color: rgba(114, 114, 114, 0.41);
    background-color: var(--main-underline-color);
    bottom: 0;
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 100%;
}

.week-day {
    display: block;
    margin: 0;
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}

.week-days {
    padding-left: 4vw;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-around;
    z-index: 2;
}

.week-day-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}

.week-days-wrapper {
    margin: auto;
    width: 95%;
    display: block;
    height: 100%;
    padding: 0;
    position: relative;
}

.tooltip{
    font-size: 12px;
    width: 200px;
}


.helper-table{
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
}