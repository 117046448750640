.card-subject {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    position: relative;
    height: 100%;
    width: 100%;
}

.class-number {
    padding-left: 0.4vw;
    padding-right: 0.4vw;
    display: inline-block;
    position: relative;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: normal;
}

.name {
    padding-left: 0.4vw;
    padding-right: 0.4vw;
    color: rgba(0, 0, 0, 0.6);
    color: var(--main-text-addition-color);
    display: inline-block;
    position: relative;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: normal;
}

.addition-info {
    padding-right: 0.4vw;
    right: 0;
    top: 0;
    color: rgba(0, 0, 0, 0.6);
    color: var(--main-text-addition-color);
    display: inline-block;
    position: absolute;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: normal;
}

.subject {
    left: 0.4vw;
    margin: 0;
    position: relative;
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}

.subject-data{
    width: 100%;
    position: absolute;
    display: flex;
    bottom: 0.5vh;

    justify-content: space-between;
    flex-flow: row wrap;
}

.underline-subject {
    bottom: 0;
    height: 0.3em;
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
}

.card-subject ul, .subject-data ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.card-subject ul li, .subject-data ul li {
    margin: 0;
    padding: 0;
}