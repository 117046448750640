.card-background-container {
    background-color: rgba(255, 255, 255, 1);
    background-color: var(--main-table-color);
    display: table;
    min-height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;

}

#bottom-line{
    bottom: 0;
}

.card-container {
    display: table-row;
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 1);
    height: 100%;
    position: relative;
}

.card-container .subject {
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
    margin-top:1em;
    position: relative;
}

.card-container .addition-info {
    display: inline-block;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: normal;
    padding-left: 0.4vw;
    position: relative;

}

.card-container .name{
    margin-bottom: 1em;
}

.card-container .class-number {
    display: inline-block;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: normal;
    padding-left: 0.4vw;
    padding-right: 0.4vw;
    position: relative;
    margin-bottom: 1em;
}

.card-container .underline-subject {
}

.card-container .subject-type {
    margin-bottom:1em;
}

.card-container .addition-info
{
    margin-bottom: 1em;
}

.card-text {
    display: block;
    font-size: 0.8vw;
    font-style: normal;
    font-weight: normal;
    padding-left: 0.4vw;
    padding-right: 0.4vw;
    position: relative;
}

li .card-text{

    margin-bottom: 1em;
}

.splitter {
    background-color: rgba(0, 0, 0, 0.87);
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: relative;
    right: 0;
    width: 90%;
    margin-bottom: 1em;
}

.subject-type{
    width: fit-content;
    padding-right: 10px;
    max-width: 150px;
    border-radius: 10px;
}

.time-subject{
    font-size: 1vw;
    margin-top: 1vh;
    text-align: right;
    margin-right: 0.5vw;
}

@media only screen and (max-width: 1366px) {
    .card-container .subject{
        margin-bottom: 0.5vh;
    }

    .card-container .name{
        font-size: 1.3vh;
    }

    .card-container .addition-info {
        font-size: 1.5vh;
    }
}

@media only screen and (max-height: 1366px) and (orientation: portrait) {
    .card-text{
        font-size: 1vh;
    }

    .card-container .subject{
        font-size: 1.5vh;
        margin-bottom: 1.5vh;
        margin-top: 0;
    }

    .card-container .class-number{
        font-size: 1.5vh;
    }

    .time-subject{
        font-size: 1vh;
    }

    .card-container .subject-type{
        padding-left: 1vw;
    }

    .card-container .name{
        font-size: 1vh;
    }
}

@media only screen and (max-width: 500px) {
    .card-text{
        font-size: 1.5vh;
    }

    .card-container .subject{
        font-size: 2vh;
        margin-bottom: 1.5vh;
        margin-top: 0;
    }

    .card-container .class-number{
        font-size: 1.5vh;
    }

    .time-subject{
        font-size: 2vh;
    }

    .card-container .subject-type{
        padding-left: 2vw;
    }

    .card-container .name{
        font-size: 1.5vh;
    }

    .card-container .addition-info {
        font-size: 1.5vh;
    }
}