@font-face {
    font-family: 'Play';
    font-style: normal;
    font-weight: 400;
    src: local('Play Regular'), local('Play-Regular'), url(https://fonts.gstatic.com/s/play/v11/6aez4K2oVqwIvtE2H68T.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Play';
    font-style: normal;
    font-weight: 700;
    src: local('Play Bold'), local('Play-Bold'), url(https://fonts.gstatic.com/s/play/v11/6ae84K2oVqwItm4TCp8y2knT.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}


.start-image{
    width: 30vw;
    height: 26vw;
}

.search-box .underline-search{
    display: none;
}

/*.search-box .field-search{
    font-family: 'Play', sans-serif;
    font-weight: 400;
}*/


.cut-corner{
    position: absolute;
    /*left: 12vw;
    top: 12vh;*/
    width: 60vw;
    height: auto;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.search-box{
    display: flex;
    position: absolute;
    /*top: 15vh;
    left: 9.5vw;*/
    left: 45%;
    top: 50%;
    /*transform: translate(-50%, -50%);*/
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.search-block{
    border: 2px solid #c2c2c2;
    border-left: 5px solid #b3b3b3;
    width: 20vw;
    margin-top: 2vh;
}

.search-header{
    font-family: 'Play', sans-serif;
    font-size: 2vw;
    color: #524d4d;
    font-weight: 700;
}

.search-info{
    /*font-family: 'Play', sans-serif;*/
    font-size: 1vw;
    color: #8d8d8d;
    font-weight: 400;
    margin-top: 8vh;
}

/*.info-block{
    margin-left: 10px;
}*/

.info-block {
    width: 30vw;
}

.field-search{
    font-size: 2vh;
}

.search-block #search-icon{
    font-size: 2vh;
}

.search-wrapper{
    margin-top: auto;
    margin-bottom: auto;
}

@media only screen and (max-height: 500px) and (orientation: landscape)
{
    .search-block .field-search{
        font-size: 4vh;
    }

    .search-block #search-icon{
        font-size: 4vh;
    }
}

@media only screen and (max-width: 1366px) {

    .start-image{
        width: 40vw;
        height: 35vw;
    }

    .cut-corner{
        width: 75vw;
    }

    .info-block {
        width: 45vw;
    }

    .search-header{
        font-size: 3vw;
    }

    .search-info{
        font-size: 2vw;
        margin-top: 5vh;
    }

    .search-block{
        width: 30vw;
    }
}

@media only screen and (orientation: portrait){

    .cut-corner{
        display: none;
    }

    .search-box{
        flex-direction: column;
    }

    .start-image{
        width: 70vw;
        height: 61vw;
        margin-right: auto;
        margin-left: auto;
    }

    .search-box{
        left: 5%;
        top: 0;
        /*transform: translate(-50%, -50%);*/
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 90%;
    }

    .info-block{
        width: 100%;
    }

    .search-header {
        font-size: 5vw;
        text-align: center;
        margin-top: 0;
        margin-right: 5%;
    }

    .search-info{
        font-size: 2.5vw;
    }

    .search-block{
        width: 100%;
        height: 3vh;
    }

    .search-block .field-search{
        font-size: 2vh;
    }

    .search-block #search-icon{
        font-size: 2vh;
    }

    .search-wrapper{
        margin-top: auto;
        margin-bottom: auto;
    }
}

@media only screen and (max-width: 450px){

    .cut-corner{
        display: none;
    }

    .search-box{
        flex-direction: column;
    }

    .start-image{
        width: 80vw;
        height: 70vw;
    }

    .search-box{
        left: 5%;
        top: 0;
        /*transform: translate(-50%, -50%);*/
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .info-block{
        width: 100%;
    }

    .search-header {
        font-size: 7vw;
        text-align: center;
        margin-top: 0;
        margin-right: 5%;
    }

    .search-info{
        font-size: 4vw;
    }

    .search-block{
        width: 95%;
        margin-right: 5%;
        margin-top: 5vh;
        height: auto;
        vertical-align: middle;
    }

    .search-block .field-search{
        font-size: 2vh;
    }

    .search-block #search-icon{
        font-size: 2vh;
    }

    .search-wrapper{
        margin-top: auto;
        margin-bottom: auto;
    }
}