* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/MaterialIcons.woff2) format('woff2');
}

.material-icons {
    cursor: pointer;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

body {
    background-color: rgba(229, 229, 229, 0.5);
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    margin: 0;
    padding: 0;
}

.content-wrapper {
    display: block;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    padding: 0;
    position: relative;
}

#header-icon, #search-icon{
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}

h3 {
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

.App{
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
}

.grid-app {
    position: fixed;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 16vw 1fr;
    margin: 0;
    padding: 0;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
}

.menu-wrapper {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 1;
}

.menu-wrapper:hover {
    width: 16vw;
    opacity: 1;
}

.menu-wrapper{
    opacity: 0.8;
}

#view-headline{
    display: none;
}

i#hide{
    display: none;
}

/*.menu-wrapper:hover + .content-wrapper {
    opacity: 0.3;
}*/

/* Desktop
@media only screen and (min-width: 1270px) {
    .grid{
        grid-template-columns: 16vw 1fr;
    }
}*/

@media only screen and (max-height: 500px) and (orientation: landscape)
{
    .h2-search-wrapper{
        display: inline-flex;
        justify-content: space-between;
        width: 80%;
    }

    #header-icon {
        position: relative;
        left: 70%;
        z-index: 3;
    }

    .underline-group{
        display: none;
    }

    .underline-subject{
        height: 0.15em;
        opacity: 80%;
    }

    .field-search{
        font-size: 3vh;
    }

    #search-icon{
        font-size: 3vh;
    }

    .teachers-card-container{
        grid-template-columns: 1fr 1fr;
    }

    .button-schedule{
        font-size: 8px;
    }

    .teacher-card {
        height: 140px;
    }

    .text-disciplines, .ul-disciplines, .card-email, .text-held, .li-held, .li-disciplines, .li-text{
        font-size: 7px;
    }

    .faculty-li, .department-li-title{
        font-size: 15px;
    }

    .teacher-card-search{
        width: 50%;
    }

    .button-schedule{
        height: 15px;
    }
}

@media only screen and (max-width: 1366px) {

    .grid-app {
        grid-template-columns: 6.5vmin 1fr;
    }

    #nav-icon {
        font-size: 5vmin;
    }

    .group {
        font-size: 4vmin;
    }

    #next-icon, #back-icon {
        font-size: 4vmin;
    }

    .subject, .nav-text, .week-day, .faculty, .date-week, h2, input, #search-icon {
        font-size: 2.3vmin;
    }

    #header-icon {
        font-size: 2vmin;
    }

    .name {
        font-size: 1.5vmin;
    }

    .class-number{
        font-size: 1vmin;
    }

    .time {
        width: calc(40px + 3.5vw);
    }

    .week-days {
        padding-left: calc(40px + 3.5vw);
    }

    #view-headline{
        display: block;
        font-size: 6.5vmin;
    }

    #hide{
        display: none;
    }

    #menu-close{
        width: 100%;
        opacity: 0.7;
    }

    #menu-open{
        opacity: 1;
        width: 40vmin;
    }

    .faculty-department{
        font-size: 10px;
    }

    /*#scrollbar{
        height: 80%;
    }*/

    .teacher-card-search{
        width: 40%;
    }

    .button-schedule{
        padding-right: 5px;
        width: 30%;
        left: 60%;
    }

}
@media only screen and (max-height: 1366px) and (orientation: landscape){

}

@media only screen and (max-height: 1366px) and (orientation: portrait) {

    .subject{
        font-size: 1.5vmin;
        top: 1vmin;
    }

    .name{
        font-size: 0.8vmin;
    }

    .class-number{
        font-size: 1vmin;
    }

    .subject-data{
        flex-direction: column;
    }

    .teachers-card-container{
        grid-template-columns: 1fr 1fr;
    }

    .teacher-card-search{
        width: 55%;
    }

    .faculty-department{
        font-size: 10px;
    }

    #scrollbar{
        height: 85%;
    }
}

/*Header also need fix if max-width was changed*/
@media only screen and (max-width: 500px) {

    .grid-app {
        grid-template-columns: 7vmin 1fr;
    }

    #nav-icon {
        font-size: 6vmin;
    }

    .menu-wrapper:hover {
        width: 70vmin;
    }

    .group {
        font-size: 3vh;
    }

    #next-icon, #back-icon {
        font-size: 3vh;
    }

    .subject, .nav-text, .week-day, .faculty, .date-week, h2, input, #search-icon {
        font-size: 2vh;
    }

    #header-icon {
        font-size: 2vh;
    }

    .name, .class-number {
        font-size: 2vh;
    }

    .time {
        width: calc(45px + 4vh);
    }

    .week-days {
        padding-left: 0;
    }

    .first-line, .last-line, .middle-line{
        border-left: none;
    }

    .tooltip{
        font-size: 10px;
        width: 150px;
    }

    .addition-info {
        font-size: 2.5vmin;
    }

    .menu-wrapper{
        width: 100%;
    }

    .menu-wrapper:hover{
        width: 60vw;
    }

    #menu-open{
        width: 60vmin;
    }

    .teachers-card-container{
        grid-template-columns: 1fr;
    }

    .teacher-card-search{
        width: 95%;
    }

    .button-schedule{
        font-size: 8px;
    }

    .teacher-card {
        height: 140px;
    }

    .text-disciplines, .ul-disciplines, .card-email, .text-held, .li-held, .li-disciplines, .li-text{
        font-size:10px;
    }

    .faculty-li, .department-li-title{
        font-size: 10px;
    }

    .button-schedule{
        height: 15px;
    }

    .faculty-list, .department-list{
        padding-left: 0;
    }

    .faculty-department{
        font-size: 10px;
    }

    #scrollbar{
        height: 85%;
    }
}

/*IPHONE X FIX shit*/
/*@media only screen and (max-width: 400px)
{
    .menu-wrapper:hover {
        width: 80vmin;
    }

    .grid-content {
        grid-template-rows: 13% 1fr !important;
    }
}*/