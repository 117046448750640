
#nav-icon {
    margin: .3vw ;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    font-size: 1.55vw;
}

.nav-text {
    padding-left: 0.3vw;
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    font-size: 1.05vw;
    font-style: normal;
    font-weight: normal;
}


/*.nav-container {
    margin: 0 auto;
    color: #000 !important;
    position: relative;
    text-decoration: none;
    width: 95%;
}*/
