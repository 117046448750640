.teacher-card {
    background: white;
    background: var(--main-table-color);
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.2);
    transition: 0.3s;
    height: 160px;
    overflow: hidden;
    flex-grow: 1;
    margin: 6px;
    white-space: nowrap;
    display: flex;
}

#cardIsClicked{
    height: min-content;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
    transform: scale(1.03);
}

.card-text-bold{
    margin-top: 7px;
    margin-bottom: 2px;
    font-weight: bold;
}

.card-title{
    margin: 10px 10px 0 10px;
    font-weight: normal;
    font-size: 17px;
    text-align: center;
    white-space: normal;
}

.teacher-card .ul-disciplines {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: normal;
    display: none;
}

.li-disciplines {
    list-style-position: inside;
    border-left: solid 2px rgba(0, 56, 255, 0.13);
    margin: 0;
    padding: 0;
    white-space: normal;
}

.button-schedule{
    width: 25%;
    display: block;
    height: 25px;
    /*box-shadow: 0 2px rgb(75, 98, 148);*/
    background-color: rgb(159, 191, 255);
    border-radius: 10px;
    border: none;
    font-style: normal;
    font-weight: bold;
    color: #ffffff;
    outline: none;
    position: relative;
    left: 70%;
    margin: 10px;
    z-index: 5;
}

.button-schedule:hover{
    background-color: rgb(75, 98, 148);
}

.teacher-card{
    display: flex;
    flex-direction: column;
}

.underline-teacher{
    background: rgba(114, 114, 114, 0.41);
    background: var(--main-underline-color);
    height: 0.5px;
    width: 80%;
    margin: 0 auto 10px auto;
}

.card-email{
    text-align: center;
    margin-top: 5px;
}

.teacher-card .text-disciplines{
    display: none;
    white-space: normal;
}

#cardIsClicked .text-disciplines{
    display: block;
    white-space: normal;
}

#cardIsClicked .ul-disciplines{
    display: block;
    white-space: normal;
}

.text-held, .li-held{
    display: inline-block;

}

.card-position-held{

}

#show-more{
    position: absolute;
    color: rgba(48, 48, 48, 0.15);
}

#cardIsClicked #show-more{
    display: none;
}

.teacher-card-search{
    background: white;
    background: var(--main-table-color);
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.2);
    transition: 0.3s;
    height: fit-content;
    overflow: hidden;
    margin: 6px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    width: 30%;
}

#close-icon{
    position: relative;
    color: #515151;
    left: 92%;
}

.teacher-card-search .ul-disciplines {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: normal;
}

.faculty-department{
    padding: 5px;
}